@import "~antd/dist/antd.less";
// @import '~antd/dist/antd.dark.less';
// @import '~antd/dist/antd.compact.less';

.App {
  text-align: center;
}

.ant-card-bordered {
  border: 1px solid #3fa9f5;
}

.ant-layout-sider-zero-width-trigger {
  opacity: 0.1;
}

.ant-layout-sider-zero-width-trigger:hover {
  opacity: 1;
}
//tshirt

#cardTshirt{
  min-width: 30vh;
  height: 50vh;
  border-width: 1mm;
  border-radius: 5px;
}
.divTshirt{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: space-between;
}
.titleProgress{
  font-size: 3vh;
  text-align: center;
}

.addMoreTshirt{
  font-size: 2vh;
}

.titleTshirt{
  text-align: center;
  padding: 1em;
}

.animationTshirt{
  width: 70%;
}

.animationTshirt img{
  border-radius: 50%;
}

.buttonTshirt{
  padding:  1em;
  text-align: center;
}

.divModalTshirt{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 400px;
}

.resultDivTshirt{
  text-align: center;
  padding-bottom: 20px;
  padding-top: 30px;
}

.formDivTshirt{
  max-width: 400px;
  padding-bottom: 20px;
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sizeModalTshirt{
  padding-bottom: 20px;
}

.formModalTshirt{
  width: 100%;
}

//signup

.signup-form-button {
  margin: 20px 0;
  width: 100%;
}

.rowSignup{
  padding-top: 20px;
  padding-bottom: 20px;
  min-height: 100vh;
}

.textAlign{
  text-align: center;
}
.checkSignup{
  margin-bottom: 5px;
}
//sav

.layoutSav{
  margin: 24px 16px 0;
  overflow: initial;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.layoutButton{
  padding-top: 50px;
}

.rowSav{
  margin: 24px 16px 0;
  overflow: initial;
  padding: 5% 15% 15% 15%;
  align-content: flex-start;
  height: 100%;
}

.topSav{
  padding-top: 40px;
}

.titleSav{
  padding-bottom: 20px;
}

.topSav form form.item select{
  width: 80%;
}

.informationSav{
  padding-top: 40px;
}

.titleInformationSav{
  padding-bottom: 20px;
}

.rowSav{
  flex-direction: row;
  align-content: flex-start;
  height: 100%;
}

.inputSav{
  background-color: rgb(189, 191, 196);
  color: #FFFFFF;
}

.formSav{
  width: 100%;
}

.buttonSav{
  width: 50%;
}


//profileCardHome

.cardProfileCardHome{
  min-width: 30vh;
  height: 50vh;
  border-width: 0;
  border-radius: 5px;
}

.background-profile-card {
  background-image: url("./images/SIN-4-2-20-PRO_home.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: space-between;
}

.div-background-profile-card{
  width: 100%;
  text-align: center;
  background-color: white;
  opacity: 0.9;
}

.title-background-profile-card{
  padding-top: 20px;
}


//profile

.flex-container-profile {
  display: flex;
  justify-content: space-evenly;
  align-items: stretch;
  width: 100%;
  flex-wrap: wrap;
  align-content: space-around;
  gap: 1.5rem;
  padding: 1rem;
  margin-top: auto;
  margin-bottom: auto;
  overflow: initial;
}

.flex-container-profile .ant-card-bordered {
  border: 0px solid #ffffff;
}

.flex-profile-card {
  flex: 1 0 50%;
  max-width: 450px;
  overflow: hidden;
  color: #000;
  border-radius: 5px;
  box-shadow: 3px 2px 14px hsla(0, 0%, 100%, 0.2);
  text-decoration: none;
  font-size: 1rem;
  transition: all 0.2s ease-in-out;
  box-shadow: 3px 5px 17px 2px rgba(26, 127, 204, 0.82);
  -moz-box-shadow: 3px 5px 17px 2px rgba(26, 127, 204, 0.82);
  -webkit-box-shadow: 3px 5px 17px 2px rgba(26, 127, 204, 0.82);
  background: #ffffff;
}

.product-profile-card{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.product-profile-card div{
  padding-top: 15px;
}
//product

.divProduct{
  margin-top: 24px;
  overflow: initial;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topProduct{
  padding-Bottom: 20px;
  display: flex;
  width: 95%;
  justify-Content: flex-start;
  align-Items: center;
  flex-Wrap: wrap;
}

.topProduct div{
  text-align: left;
  flex: 1;
  width: 95%;
}

.buttonProduct{
  text-align: center;
}

.tableProduct{
  max-width: 95%;
  width: 95%;
}

.withoutProduct{
  margin-top: 24px;
  overflow: initial;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleWithoutProduct{
  flex: 1 0 100%; 
  text-align: left; 
  width: 95%;
}

.divCarousel{
  flex: 1 0 100%;
  width: 300px;
  height: auto;
  text-align: center;
}
.imgCarousel{
  margin: auto;
  width: 90%;
  height: 100%;
}
.buttonAddProduct{
  flex: 1;
  text-align: center;
}

#buttonProductLg {
  min-width: 300px;
  min-height: 70px;
  font-size: 22px;
  letter-spacing: 1.3px;
  font-weight: 700;
}

.smallWithoutProduct{
  overflow: initial;
  width: 100%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.smallDivCarousel{
  flex: 1 0 100%;
  width: 200px;
  height: 200px;
  text-align: center;
}

.buttonSmallWithoutProduct{
  margin: auto;
  flex: 1;
  text-align: center;
}

#buttonProductSl {
  width: 150px;
  height: 40px;
  font-size: 14px;
  letter-spacing: 1.3px;
  font-weight: 700;
}
//notFound

.rowNotFound{
  height: 100vh;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  margin: auto;
}

.imageNotFound{
  width: 100%;
}

.titleNotFound{
  text-align: center;
  padding-top: 30px;
}

.colButtonNotFound{
  padding-top: 30px;
}

//login

.rowLogin{
  height: 100vh;
}

.logo-login{
  text-align: center;
}

.logo-login-img{
  text-align: center;
}

.card-login .ant-card-body,
.card-signup .ant-card-body {
  padding: 24px 35px 24px 35px;
}

/*small screen login 320px*/
.card-login form {
  @media only screen and (max-width: 320px) and (orientation: portrait) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
}

.card-login form-item {
  @media only screen and (max-width: 280px) and (orientation: portrait) {
    flex: 1;
  }
}

.title-card-login{
  text-align: center;
}

.item-pwd-login{
  text-align: center;
}

.button-submit-login{
  text-align: center;
}

.login-form-button{
  width: 100%;
  margin-top: 15px;
}

//home
.home-main-container {
  display: flex;
  flex-direction: column;
  overflow: initial;
}

.home-top-container {
  background-color: white;
  flex-direction: column;
  flex: 1;
}

.flex-container-home {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  justify-items: center;
  align-items: flex-start;
}

.flex-home-product-child {
  flex: 1 1 calc(23.5% - 1em);
  margin: 1em;
}

.home-product-card{
  min-width: 30vh;
  min-height: 50vh;
  border-width: 0mm;
  padding: 0;
}

#home-description{
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: space-between;
}

#title-home-product-card{
  font-size: 3vh;
   text-align: center;
}

#desc-home-product-card{
  font-size: 2vh; 
  text-align: center;
}
#button-home-product-card{
  font-size: 2vh;
  text-align: center;
  height: 100%;
}

.flex-home-child {
  max-width: 80%;
  flex: 1 1 calc(25.5% - 1em);
  margin: 1em;
}

.home-bottom-container {
  flex-direction: column;
  flex: 1;
}

.home-container-tab {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.title-home-container-tab{
  text-align: center;
  width: 100%;
}

.table-products .ant-table-tbody > tr.ant-table-row:hover > td {
  background-color: #f58220;
  color: #ffffff;
}

.table-products {
  align-items: center;
  width: 95%;
  max-width: 95%;
}

.table-products .ant-btn-text a {
  color: black;
}

.table-products .ant-btn-text {
  border: 1px solid black;
}

.table-products .ant-btn-text:hover a,
.ant-btn-text:focus a {
  color: #ffffff;
}

.table-products .ant-btn-text:hover,
.ant-btn-text:focus {
  color: #ffffff;
  border: 1px solid #ffffff;
}

#imageHome{
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  height: 100%;
}

.cardSavHome{
  min-width: 30vh;
  height: 50vh;
  border-width: 0mm;
  border-radius: 5px;
}

.background-sav-card {
  background-image: url("./images/support.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  align-content: space-between;
}

.text-sav-card{
  width: 100%;
  text-align: center;
  background-color: white;
  opacity: 0.9;
}

#title-sav-card{
  padding-top: 20px;
}
.textBottom {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.background-bottom-home {
  background-image: url("./images/homeBottomImage.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.background-tshirt-card {
  background-image: url("./images/t-shirt.jpg");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
#imageBottom {
  background-image: url("./images/homeBottomImage.png");
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  display: flex;
  margin: 1em;
}

//forgotPassword

.rowForgotPwd{
  height: 100vh;
}

#submitButton{
  width: 100%;
  margin-bottom: 2em;
}

#clickPwd{
  text-align: center;
}

//dashboard

.bottom_dash {
  position: absolute;
  bottom: 0;
  z-index: 0;
  transition: all 0.2s;
}

.menu_bottom_cgv1 a:hover {
  background-color: transparent;
  color: white;
}

.menu_bottom_cgv1 a {
  color: rgba(255, 255, 255, 0.65);
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 11px;
  transition: color 0.3s;
}

.logo-login img {
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.siderDashboard{
  text-align: center;
  color: white;
  min-height: -webkit-fill-available;
}

.siderDashboard > div{
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#divTopDashboard{
  padding-top: 1rem;
  padding-bottom: 1rem;
}
#divTopDashboard h1{
  color: white;
}

#divTopDashboard h4{
  color: white;
  padding-top: 30px;
}

#divTopDashboard h5{
  color: white;
}

#navDashboard{
  flex: 1;
}


//modal addProduct

#generalDiv{
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

#titleDiv{
  flex: 1;
  align-self: center;
  text-align: center;
}

#progressDiv{
  width: 90%;
}

#successDiv{
  flex: wrap;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
}

#successDiv div:first-child{
  text-align: center;
}

#successDiv div:nth-child(1){
  margin-top: 10px;
  margin-bottom: 10px;
}

#successDiv div:last-child{
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}

#successDiv Input{ 
  text-align: center;
}

#successDiv Button{ 
  width:50%;
}

//modal product information

.divModal {
  width: 100%;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.divTitle {
  flex: 1;
  align-self: center;
  text-align: center;
  border-radius: 10px 10px 10px 9px;
  -webkit-border-radius: 10px 10px 10px 9px;
  -moz-border-radius: 10px 10px 10px 9px;
  border: 4px outset #59a9ef;
  background: #46abf2;
  margin-right: 3vw;
  margin-left: 3vw;
}

.divSerialNum {
  align-items: stretch;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
}

#pDivSerialNum1 {
  margin-right: 5px;
  margin-top: 10px;
  font-weight: bold;
  font-size: 2vh;
}

#pDivSerialNum2 {
  margin-top: 10px;
  font-size: 2vh;
}

.divTop {
  display: flex;
  flex-wrap: wrap;
  justify-items: center;
  align-items: stretch;
  justify-content: center;
}

.divTop > div {
  margin: 1em;
  text-align: center;
  max-width: 90%;
  flex: 1 1 calc(40.5% - 1em);
  align-items: center;
  justify-content: space-between;
}

.divTop > div p {
  margin-top: 2vw;
  margin-right: 3vw;
  margin-left: 3vw;
  font-size: 2vh;
  color: #958888;
}

.divTop > div div {
  width: 100%;
  display: flex;
  justify-content: center;
}
.divTop > div div button {
  border-radius: 10px 10px 10px 9px;
  background: #f58220;
  text-align: center;
}

.divTop > div div button:hover {
  border-radius: 10px 10px 10px 9px;
  background: #fdc89d;
  text-align: center;
}

.divTop > div div button a {
  color: white;
  margin-left: 8px;
}

.divTop div:nth-child(2) {
  min-width: 50px;
  justify-content: center;
  margin: 1em;
  flex: 1 0 45%;
  align-items: center;
}

.dividerModal {
  margin-top: 3vh;
  margin-bottom: 3vh;
  border-width: 3;
  border-color: #f8ad1c;
}

#titleModalProduct {
  color: #ffffff;
  text-shadow: 3px 5px 2px #474747;
}

.ant-modal-centered .buttonGuide {
  margin-left: 3vw;
  margin-right: 3vw;
}

.leftColumn p {
  font-size: clamp(12px, 2vw, 16px);
  font-weight: bold;
  width: clamp(140px, 2vw, 16px);
}
.rightColumn p {
  font-size: clamp(12px, 2vw, 16px);
}

//exception small screen
.leftColumn p {
  @media only screen and (max-width: 352px) and (orientation: portrait) {
    font-size: clamp(8px, 1vw, 16px);
    font-weight: bold;
    width: clamp(90px, 1vw, 16px);
  }
}
.rightColumn p {
  @media only screen and (max-width: 352px) and (orientation: portrait) {
    font-size: clamp(8px, 1vw, 16px);
  }
}

.tabModal {
  padding: 2vh;
  background: white;
  border-radius: 10px 10px 10px 9px;
}

.divBottom {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.buttonSupport {
  border-radius: 10px 10px 10px 9px;
  background: #dfdcdc;
  text-align: center;
  color: "white";
  margin: 2vh;
}

@primary-color: #3FA9F5;@btn-primary-bg: #F58220;@layout-body-background: #F3F3F3;@heading-3-size: 1.7em;